<template>
    <v-app-bar app color="white" class="elevation-1">
        <v-spacer></v-spacer>
        <v-app-bar-title href="/" class="orange--text">UMOJA</v-app-bar-title>
        <v-spacer></v-spacer>
        <v-avatar>
            <img :src="$store.state.ngayi.image" class="img-fluid" />
        </v-avatar>
        <!-- <h6>{{ $store.state.ngayi.fullname }}</h6> -->
        <!-- <v-btn text color="black" class="font-weight-bold">{{$store.state.ngayi.fullname}}</v-btn> -->

        <!-- <v-btn text color="black" class="font-weight-bold" href="/">Home</v-btn>
        <v-btn text color="black" class="font-weight-bold" href="#/myAssistance">My Requests</v-btn>
        <v-btn text color="black" class="font-weight-bold" href="#/contact">Contact</v-btn> -->
        <!-- <v-spacer></v-spacer> -->
    </v-app-bar>
</template>

<script>
    export default {

    }
</script>

<style>

</style>